<template>
    <PublicForm :busy="busy">
        <v-form ref="form" v-model="valid" @submit.prevent="">
            <v-row dense>
                <v-col class="py-8">
                    <BreezeLogo style="height: 50px" max-height="50" />
                </v-col>
                <v-col cols="12" class="px-6">
                    <LoginPageError :error="error" />
                    <p v-if="token">
                        Your account is protected with two-factor
                        authentication. Please enter your current one-time
                        password.
                    </p>
                </v-col>
            </v-row>
            <v-row dense v-if="!token">
                <v-col cols="12" class="px-6">
                    <v-text-field
                        label="Username"
                        ref="username"
                        name="username"
                        v-model="username"
                        prepend-icon="mdi-account"
                        type="text"
                        placeholder=" "
                        :rules="[
                            (v) => !!v || 'Required',
                        ]"
                        :disabled="busy"
                        v-on:keyup.enter="login"
                        required
                        :autofocus="!username"
                    />
                    <v-text-field
                        id="password"
                        ref="password"
                        label="Password"
                        name="password"
                        placeholder=" "
                        v-model="password"
                        prepend-icon="mdi-lock"
                        :rules="[(v) => !!v || 'Required']"
                        :disabled="busy"
                        type="password"
                        v-on:keyup.enter="login"
                        required
                        :autofocus="!!username"
                    />
                    <v-checkbox
                        v-if="!$store.state.app.standalone"
                        label="Remember my Username on this device"
                        ref="remember"
                        name="remember"
                        v-model="remember"
                        :disabled="busy"
                        @change="updateRemember"
                    />
                </v-col>
                <v-fade-transition hide-on-leave>
                    <v-col
                        key="reCaptcha"
                        v-show="!recaptcha.response && !recaptcha.disabled"
                        align="center"
                        style="min-height: 102px"
                        cols="12"
                    >
                        <vue-recaptcha
                            ref="captcha"
                            :sitekey="recaptcha.sitekey"
                            :loadRecaptchaScript="true"
                            :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                            @verify="recaptchaVerified"
                            @expired="recaptchaExpired"
                        />
                    </v-col>
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                    <v-col
                        key="loginButton"
                        v-show="recaptcha.response || recaptcha.disabled"
                        style="min-height: 102px"
                        class="pt-8 px-6"
                        cols="12"
                    >
                        <v-btn
                            v-on:click="login"
                            :loading="busy"
                            color="primary"
                            :disabled="
                                !valid || (!recaptcha.response && !recaptcha.disabled)
                            "
                            block
                            large
                            >Login</v-btn
                        >
                    </v-col>
                </v-fade-transition>
                <v-col class="text-center my-3" :disabled="busy">
                    <router-link
                        to="/forgotpassword"
                        :disabled="!busy"
                        :event="!busy ? 'click' : ''"
                        v-bind:class="{ 'text--disabled': busy }"
                        >Forgot password?</router-link
                    >
                </v-col>
            </v-row>
            <v-row dense v-if="token">
                <v-col class="px-6" cols="12">
                    <v-text-field
                        label="One time password"
                        ref="otp"
                        name="otp"
                        v-model="otp"
                        prepend-icon="mdi-key"
                        @submit.prevent
                        type="text"
                        :rules="[
                            (v) => !!v || 'Required',
                            (v) => /^\d{6}$/.test(v) || 'Invalid OTP',
                        ]"
                        maxlength="6"
                        :disabled="busy"
                        v-on:keydown.enter="validateOTP"
                        required
                        autofocus
                    />
                </v-col>
                <v-col key="twoFactorAuthButton" class="pt-8 px-6" cols="12">
                    <v-btn
                        v-on:click="validateOTP"
                        color="primary"
                        :loading="busy"
                        :disabled="!valid"
                        block
                        large
                        >Validate</v-btn
                    >
                </v-col>
                <v-col class="pb-8 px-6" cols="12">
                    <v-btn @click="cancelOTP" :disabled="busy" block large
                        >Cancel</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </PublicForm>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import PublicForm from '../../components/pieces/Public/PublicForm';
import LoginPageError from '../../components/pieces/Public/LoginPageError';
import BreezeLogo from '../../components/pieces/Public/BreezeLogo';
import apiMixin from '../../mixins/apiMixin';

export default {
    name: 'Login',
    mixins: [apiMixin],
    data() {
        return {
            valid: false,
            username: '',
            password: '',
            otp: '',
            remember: false,
            token: null,
            error: '',
            busy: false,
            recaptcha: {
                sitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
                response: '',
                disabled: false
            },
        };
    },
    methods: {
        async login() {
            if (!this.valid || (!this.recaptcha.response && !this.recaptcha.disabled)) return false;
            this.busy = true;
            const response = await this.Api.send('post','auth/login', {
                login: this.username,
                password: this.password,
                recaptcha_response: this.recaptcha.response,
            });
            if (response.success) {
                this.error = '';
                if (response.data.twofactor_required) {
                    this.$refs.form.resetValidation();
                    this.token = response.data.token;
                    this.busy = false;
                } else {
                    this.loginSuccess(response.data.token);
                }
            } else {
                this.recaptcha.response = '';
                this.$refs['captcha'].reset();
                this.password = '';
                this.busy = false;
                this.error =
                    response.status === 403
                        ? 'Access forbidden - your IP address or region is not allowed'
                        : response.errorDetail || 'An unknown error occurred';
                this.$refs.form.resetValidation();
                this.$nextTick(() => {
                    this.$refs.password.focus();
                });
            }
        },
        async validateOTP() {
            this.busy = true;
            const response = await this.Api.send('post','auth/otp', {
                token: this.token,
                otp: this.otp,
            });
            if (response.success) {
                this.loginSuccess(response.data.token);
            } else {
                this.busy = false;
                this.error =
                    response.errorDetail || 'An unknown error occurred';
                this.otp = '';
                this.$refs.form.resetValidation();
                this.$nextTick(() => {
                    this.$refs.otp.focus();
                });
            }
        },
        cancelOTP: function () {
            this.busy = true;
            this.otp = '';
            this.password = '';
            this.token = null;
            this.recaptcha.response = '';
            this.error = '';
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
            this.busy = false;
        },
        recaptchaVerified: function (response) {
            this.recaptcha.response = response;
        },
        recaptchaExpired: function () {
            this.recaptcha.response = '';
        },
        loginSuccess: function (token) {
            if (this.remember){
              //Note this is a feature that just remembers the login username for the user.
              //Note that the username can also be passed as a query string with 'username' parameter
              localStorage.login = this.username;
            }

            //Note the login token is stored here. We still need to get a session token.
            //This happens in the router/index.js.
            this.$store.commit('login', token);
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect).catch(() => {});
            } else {
                this.$router.push('/').catch(() => {});
            }
        },
        updateRemember(value) {
            if (!value && localStorage.login) delete localStorage.login;
        },
    },
    watch: {
        otp(value) {
            if (/^\d{6}$/.test(value) && !this.error) {
                this.validateOTP();
            }
        }
    },
    created() {
        this.recaptcha.disabled = (process.env.VUE_APP_DISABLE_RECAPTCHA === 'true');
        if (this.$route.params.error) this.error = this.$route.params.error;
        if (localStorage.login) {
            this.username = localStorage.login;
            this.remember = true;
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        }else if(this.$route.query.username != null){
            this.username = this.$route.query.username;
        }
    },
    components: {
        BreezeLogo,
        LoginPageError,
        PublicForm,
        VueRecaptcha,
    },
};
</script>
